<template>
  <div class="mx-auto">
    <div>
      <Loading :isLoading="this.isLoading"/>

      <div>
        <div class="flex-1 bg-gray-0 px-0 py-1 m-1">
          <div class="row">
            <center>
              <p class="lg:text-3xl lg:text-3xl lg:text-3xl lg:text-4xl xl:text-5xl title"
                 style="margin-top:40px;">Cambiar Contraseña</p>
            </center>
          </div>
        </div>
        <div class="flex-1 bg-gray-0 px-0 py-1 m-1">
          <div class="row">
            <center>
              <p class="text-md subTitle">
                Te recomendamos que por seguridad, elegir una contraseña única que no uses para<br> 
                conectarte a otras cuentas
              </p>
            </center>
            <center>
              <!-- <p v-if="errors.length">
                  <ul>
                      <li v-for="error in errors"><h5 style="color:red">{{ error }}</h5></li>
                  </ul>
              </p> -->
              <br>
            </center>
          </div>
        </div>
        <div class="flex-1 bg-gray-0 px-0 py-0 m-0">
          <div class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2">
            <div class="row mycontent-left">
              <form @submit="changePassword" class="px-8 pt-6 pb-8 mb-4" style="text-align: left;">
                <!-- <div class="form-group" style="margin-top:-15px;">
                    <label style="text-align: left;font-size:15px;color:black;color: #6E6E6E;">Contraseña actual:</label>
                    <div class="input-group" style="margin-top:-5px;">
                        <input type="password" style="border:1px solid #bfd243;margin-top:-0px" class="form-control" id="inputCurrentPassword"
                        minlength="8" maxlength="30" v-model="currentPassword" required>
                        <span tabIndex="-1" class="input-group-addon" style="background-color:#ffffff;"><button type="button" tabIndex="-1" v-on:click="changeIcoCurrentPassword()">
                        <div tabIndex="-1" id="divImageHideCurrentPasswordOn"><img id="imageHideCurrentPasswordOn" src="@/assets/visibility-off.svg" width="20" height="20" style="margin-right:18px;"></div>
                        <div tabIndex="-1" id="divImageHideCurrentPasswordOff" style="visibility:collapse"><img img tabIndex="-1" id="imageHideCurrentPasswordOff" src="@/assets/visibility-on.svg"  width="0" height="0" style="margin-right:18px;"></div>
                        </button>
                        </span>
                    </div>
                </div>
                <br> -->

                <div class="form-group" style="margin-top:-15px;">
                  <label class="label-input" style="text-align: left;color: #8e857a;">Contraseña nueva</label>
                  <div class="input-group" style="margin-top:-5px;">
                    <input type="password" class="shadow-md border-2 rounded-lg py-3 w-full px-3 mt-2" id="Input_NewPassword" minlength="8" maxlength="30" v-model="newPassword" required>
                    <span tabIndex="-1" class="input-group-addon" style="position:absolute;margin-left: -40px;margin-top: 23px;"><button
                        type="button" tabIndex="-1" v-on:click="changeIcoNewPassword()">
                                                <div tabIndex="-1" id="divImageHideNewPasswordOn"><img
                                                    id="imageHideNewPasswordOn" src="@/assets/visibility-off.svg"
                                                    width="20" height="20" style="margin-right:18px;"></div>
                                                <div tabIndex="-1" id="divImageHideNewPasswordOff"
                                                     style="visibility:collapse"><img tabIndex="-1"
                                                                                      id="imageHideNewPasswordOff"
                                                                                      src="@/assets/visibility-on.svg"
                                                                                      width="0" height="0"
                                                                                      style="margin-right:18px;"></div>
                                            </button>
                                            </span>
                  </div>
                </div>
                <br>

                <div class="form-group" style="margin-top:-15px;">
                    <label class="label-input" style="text-align: left;color: #8e857a; margin-bottom:4px;margin-top:20px">Verificar contraseña nueva:</label>
                  <div class="input-group" style="margin-top:-5px;">
                    <input type="password" style="" class="shadow-md border-2 rounded-lg py-3 w-full px-3 mt-2" id="Input_ConfirmNewPassword" minlength="8" maxlength="30" v-model="confirmNewPassword" required>
                    <span tabIndex="-1" class="input-group-addon" style="position:absolute;margin-left: -40px;margin-top: 23px;"><button
                        type="button" tabIndex="-1" v-on:click="changeIcoConfirmNewPassword()">
                                              <div tabIndex="-1" id="divImageHideConfirmNewPasswordOn"><img
                                                  id="imageHideConfirmNewPasswordOn"
                                                  src="@/assets/visibility-off.svg" width="20" height="20"
                                                  style="margin-right:18px;"></div>
                                              <div tabIndex="-1" id="divImageHideConfirmNewPasswordOff"
                                                   style="visibility:collapse"><img tabIndex="-1"
                                                                                    id="imageHideConfirmNewPasswordOff"
                                                                                    src="@/assets/visibility-on.svg"
                                                                                    width="0" height="0"
                                                                                    style="margin-right:18px;"></div>
                                            </button></span>
                  </div>
                </div>
                <center>
                  <button class="next_button mt-10" id="Button_ChangePassword" style="height:40px;width:240px; background-color:#fdce00" type="submit, text-decoration:none;border-color:#fdce00;background:#fdce00;">
                      Cambiar contraseña
                  </button>
                </center>
                <br>
                <br>
              </form>
            </div>
            <div class="w-full">
              <div class="flex grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lx:grid-cols-1">
                <div style="margin-left:50px;color: #726659;">
                  <div style="margin-top:15px;">
                    <p class="md:text-lg text-Roboto-regular">
                      La contraseña no debe ser la misma.
                    </p>
                  </div>
                  <div style="margin-top:15px;">
                    <p class="md:text-lg text-Roboto-regular">
                      Tu contraseña debe tener al<br>menos un número.
                    </p>
                  </div>
                  <div style="margin-top:15px">
                    <p class="md:text-lg text-Roboto-regular">
                      La contraseña no debe contener<br>espacios.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css">-->
    <!--<link href="@/node_modules/tailwindcss/dist/tailwind.min.css" rel="stylesheet"> -->
  </div>
</template>


<script>
import('@/assets/css/stylesheet.css')

import MenuLateral from '@/components/LateralMenu.vue';
import Loading from '@/components/Loading/VueLoading.vue'
import Swal from "sweetalert2";
import {getTokenDecoden} from "@/helpers/tokenauth";

export default {
  data() {
    return {
      errors: [],
      apiToken: null,
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      currentPasswordHide: false,
      newPasswordHide: false,
      confirmNewPasswordHide: false,
      email: "",
      isLoading: false,
      multiApi: process.env.VUE_APP_MULTIAPI,
      ccapi: process.env.VUE_APP_CCAPI,
      business: process.env.VUE_APP_BUSINESS_FONCABSA,
    };
  },
  name: 'CambiarContraseña',
  components: {
    Loading,
    MenuLateral
  },
  props: {
    msg: String
  },
  async mounted() {
    await this.getLoginCredentials();
  },
  beforeMount() {
    console.log("Cambiar Contraseña");
  },
  methods: {
    async getLoginCredentials() {
      this.accountType = sessionStorage.getItem('login');
    },
    changeIcoCurrentPassword() {

      if (this.currentPasswordHide) {
        document.getElementById("inputCurrentPassword").setAttribute('type', 'password');
        document.getElementById('divImageHideCurrentPasswordOff').style.visibility = "collapse";
        document.getElementById('imageHideCurrentPasswordOff').width = "0"
        document.getElementById('imageHideCurrentPasswordOff').height = "0"

        document.getElementById('divImageHideCurrentPasswordOn').style.visibility = "visible";
        document.getElementById('imageHideCurrentPasswordOn').width = "20"
        document.getElementById('imageHideCurrentPasswordOn').height = "20"
        this.currentPasswordHide = false;
      } else {
        document.getElementById("inputCurrentPassword").setAttribute('type', 'text');
        document.getElementById('divImageHideCurrentPasswordOn').style.visibility = "collapse";
        document.getElementById('imageHideCurrentPasswordOn').width = "0"
        document.getElementById('imageHideCurrentPasswordOn').height = "0"

        document.getElementById('divImageHideCurrentPasswordOff').style.visibility = "visible";
        document.getElementById('imageHideCurrentPasswordOff').width = "20"
        document.getElementById('imageHideCurrentPasswordOff').height = "20"
        this.currentPasswordHide = true;
      }
    },
    changeIcoNewPassword() {

      if (this.newPasswordHide) {
        document.getElementById("Input_NewPassword").setAttribute('type', 'password');
        document.getElementById('divImageHideNewPasswordOff').style.visibility = "collapse";
        document.getElementById('imageHideNewPasswordOff').width = "0"
        document.getElementById('imageHideNewPasswordOff').height = "0"

        document.getElementById('divImageHideNewPasswordOn').style.visibility = "visible";
        document.getElementById('imageHideNewPasswordOn').width = "20"
        document.getElementById('imageHideNewPasswordOn').height = "20"
        this.newPasswordHide = false;
      } else {
        document.getElementById("Input_NewPassword").setAttribute('type', 'text');
        document.getElementById('divImageHideNewPasswordOn').style.visibility = "collapse";
        document.getElementById('imageHideNewPasswordOn').width = "0"
        document.getElementById('imageHideNewPasswordOn').height = "0"

        document.getElementById('divImageHideNewPasswordOff').style.visibility = "visible";
        document.getElementById('imageHideNewPasswordOff').width = "20"
        document.getElementById('imageHideNewPasswordOff').height = "20"
        this.newPasswordHide = true;
      }
    },
    changeIcoConfirmNewPassword() {

      if (this.confirmNewPasswordHide) {
        document.getElementById("Input_ConfirmNewPassword").setAttribute('type', 'password');
        document.getElementById('divImageHideConfirmNewPasswordOff').style.visibility = "collapse";
        document.getElementById('imageHideConfirmNewPasswordOff').width = "0"
        document.getElementById('imageHideConfirmNewPasswordOff').height = "0"

        document.getElementById('divImageHideConfirmNewPasswordOn').style.visibility = "visible";
        document.getElementById('imageHideConfirmNewPasswordOn').width = "20"
        document.getElementById('imageHideConfirmNewPasswordOn').height = "20"
        this.confirmNewPasswordHide = false;
      } else {
        document.getElementById("Input_ConfirmNewPassword").setAttribute('type', 'text');
        document.getElementById('divImageHideConfirmNewPasswordOn').style.visibility = "collapse";
        document.getElementById('imageHideConfirmNewPasswordOn').width = "0"
        document.getElementById('imageHideConfirmNewPasswordOn').height = "0"

        document.getElementById('divImageHideConfirmNewPasswordOff').style.visibility = "visible";
        document.getElementById('imageHideConfirmNewPasswordOff').width = "20"
        document.getElementById('imageHideConfirmNewPasswordOff').height = "20"
        this.confirmNewPasswordHide = true;
      }
    },
    changePassword(e) {
      e.preventDefault();
      this.isLoading = true
      this.errors = []
      var peticion = this.validatePassword();

      if(!peticion){
        this.isLoading = false;
        return;
      }

      if (peticion == true) {
        let data = {
          token_auth: sessionStorage.getItem("login"),
          password: this.newPassword,
          confirm_password: this.confirmNewPassword
        };
        this.axios.post(this.ccapi + `/${process.env.VUE_APP_BUSINESS_FONCABSA}/account/change_password`, data,
            {
              'Content-Type': 'application/json'
            })
            .then(response => {
              this.isLoading = false
              Swal.fire({
                title: "Aviso",
                text: "Se cambió la contraseña correctamente",
                icon: "success",
                confirmButtonColor: '#FEB72B',
              }).then(()=>{
                this.$router.push({ path: "/login" });
              });
            }).catch(error => {
          this.isLoading = false
          this.errors = error.response.data.errores
          Swal.fire({
            title: "Aviso",
            text: this.errors,
            icon: "error",
            confirmButtonColor: '#FEB72B',
          })
        });
      } else {
        this.isLoading = false
      }
    },
    validatePassword(){
      if (this.newPassword.includes(" ") == true || this.confirmNewPassword.includes(" ") == true) {
        this.errors.push("La contraseña no puede contener espacios")
        Swal.fire({
          title: "Aviso",
          text: this.errors,
          icon: "warning",
          confirmButtonColor: '#FEB72B',
        })
        return false;
      }

      if (this.newPassword != this.confirmNewPassword) {
        this.errors.push("Las contraseñas son diferentes")
        Swal.fire({
          title: "Aviso",
          text: this.errors,
          icon: "warning",
          confirmButtonColor: '#FEB72B',
        })
        return false
      }
      return true;
    }
  }
}
</script>

<style scoped>
#panel {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.text-Roboto-regular{
  font-family: 'RobotoRegular' !important;
}

input[type="password"]:focus-visible{
  border: 2px solid #810042 ;
  outline: none;
}
</style>